// formulate.config.js
import { de } from '@braid/vue-formulate-i18n'

export default {
  plugins: [de],
  locale: 'de',
  useInputDecorators: false,
  validationNameStrategy: ['validationName', 'label', 'name', 'type'],
  locales: {
    de: {
      required({ name }) {
        name = name.replace(/\s*\*$/, '')
        return `Das Feld "${name}" ist ein Pflichtfeld.`
      },
      min({ name, args }) {
        name = name.replace(/\s*\*$/, '')
        return `Das Feld "${name}" muss mindestens ${args[0]} Zeichen lang sein.`
      },
      alphanumeric({ name }) {
        name = name.replace(/\s*\*$/, '')
        return `Das Feld "${name}" ist darf nur Buchstaben und Zahlen enthalten.`
      },
      alpha({ name }) {
        name = name.replace(/\s*\*$/, '')
        return `Das Feld "${name}" ist darf nur Buchstaben und Zahlen enthalten.`
      },
      nameRule({ name }) {
        name = name.replace(/\s*\*$/, '')
        return `Das Feld "${name}" ist darf nur Buchstaben und Trennzeichen enthalten.`
      },
      codeRule({ name }) {
        name = name.replace(/\s*\*$/, '')
        return `Das Feld "${name}" ist darf nur Buchstaben, Zahlen und Trennzeichen enthalten.`
      },
      phoneRule({ name }) {
        name = name.replace(/\s*\*$/, '')
        return `Das Feld "${name}" ist keine gültige Telefonnummer im Format +436767676767.`
      },
      testAboRule() {
        return `Bitte wählen Sie das gewünschte Testabo aus.`
      },
      dateRule({ name }) {
        return `Das Feld "${name}" ist kein gültiges Datum im Format dd.mm.JJJJ.`
      },
    },
  },
}
