import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
const options = {"environment":"production","appVersion":"4070ffc6","enabledReleaseStages":["production","dev"],"releaseStage":"production","apiKey":"048f5c3b2cdbd7546eca5e848d78c48a"}
options.plugins = [new BugsnagPluginVue()]

let bugsnagClient

export default function ({ app }, inject) {
  if (!Bugsnag._client) {
    bugsnagClient = Bugsnag.start(options)
    Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
  } else {
    bugsnagClient = Bugsnag._client
  }

  inject('bugsnag', bugsnagClient)
}
